exports.components = {
  "component---src-layouts-case-study-index-tsx": () => import("./../../../src/layouts/CaseStudy/index.tsx" /* webpackChunkName: "component---src-layouts-case-study-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-destinations-tsx": () => import("./../../../src/pages/destinations.tsx" /* webpackChunkName: "component---src-pages-destinations-tsx" */),
  "component---src-pages-etl-tools-index-tsx": () => import("./../../../src/pages/etl-tools/index.tsx" /* webpackChunkName: "component---src-pages-etl-tools-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-podcasts-index-tsx": () => import("./../../../src/pages/podcasts/index.tsx" /* webpackChunkName: "component---src-pages-podcasts-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-sources-tsx": () => import("./../../../src/pages/sources.tsx" /* webpackChunkName: "component---src-pages-sources-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-why-index-tsx": () => import("./../../../src/pages/why/index.tsx" /* webpackChunkName: "component---src-pages-why-index-tsx" */),
  "component---src-templates-author-index-tsx": () => import("./../../../src/templates/author/index.tsx" /* webpackChunkName: "component---src-templates-author-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog-post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-connection-tsx": () => import("./../../../src/templates/connection.tsx" /* webpackChunkName: "component---src-templates-connection-tsx" */),
  "component---src-templates-connector-index-tsx": () => import("./../../../src/templates/connector/index.tsx" /* webpackChunkName: "component---src-templates-connector-index-tsx" */),
  "component---src-templates-etl-tools-index-tsx": () => import("./../../../src/templates/etl-tools/index.tsx" /* webpackChunkName: "component---src-templates-etl-tools-index-tsx" */)
}

